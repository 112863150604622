import request from '@/libs/request'
import { data } from 'jquery';

var debug = false
var id = 1000;
var cache = [
  {
    "id": 2000,
    "fenceName": "fence1",
    "fenceShape": 1,
    "fenceTimingType": 1,
    "fencePoints": [
      {
        "longitude": 117.085938,
        "latitude": 40.144635
      }
    ],
    "triggerScope": 10164.496137862265,
    "fencePath": "(117.085938,40.144635,10164.496137862265)",
    "adcode": "0",
    "cityname": ",,"
  },
  {
    "id": 2001,
    "fenceName": "fence2",
    "fenceShape": 2,
    "fenceTimingType": 1,
    "fencePoints": [
      {
        "longitude": 115.825723,
        "latitude": 39.17412
      },
      {
        "longitude": 115.765932,
        "latitude": 39.098896
      },
      {
        "longitude": 115.936107,
        "latitude": 39.002062
      },
      {
        "longitude": 115.970602,
        "latitude": 39.163379
      },
      {
        "longitude": 115.897013,
        "latitude": 39.165169
      }
    ],
    "fencePath": "(115.825723,39.17412),(115.765932,39.098896),(115.936107,39.002062),(115.970602,39.163379),(115.897013,39.165169)",
    "adcode": "0",
    "cityname": ",,"
  },
  {
    "id": 2002,
    "fenceName": "fence3",
    "fenceShape": 2,
    "fenceTimingType": 2,
    "fencePoints": [
      {
        "longitude": 117.68562,
        "latitude": 40.118625
      },
      {
        "longitude": 117.686303,
        "latitude": 40.118625
      },
      {
        "longitude": 117.686303,
        "latitude": 40.118101
      },
      {
        "longitude": 117.68562,
        "latitude": 40.118101
      }
    ],
    "fencePath": "(117.68562,40.118625),(117.686303,40.118625),(117.686303,40.118101),(117.68562,40.118101)",
    "adcode": "0",
    "cityname": ",,"
  }
]

// 查询电子围栏表列表
export function listMapfence(query) {
  if (debug) {
    let cached = cache.filter(c => !query.fenceName || c.fenceName === query.fenceName)
    let start = Math.max((query.pageNum || 1) - 1, 0) * (query.pageSize || 10)
    let end = start + (query.pageSize || 10)
    return Promise.resolve({
      data:{
        count: cache.length, 
        list: cached.slice(start, end)
      }
    });
  }
  return request({
    url: '/shipping_fence/list',
    method: 'post',
    data: query
  })
}

// 查询电子围栏表详细
export function getMapfence(name) {
  if (debug) {
    let cached = cache.filter(c => c.name === name)
    if (cached.length) {
      return Promise.resolve({
        data: cached[0]
      });
    } else {
      return Promise.reject({
        code: 404,
        msg: 'data not found'
      })
    }
  }
  return listMapfence({fenceName: name, pageNum: 1, pageSize: 1})
  .then((response) => {
    if (response.data && response.data.length) {
      return {
        data: response.data[0]
      };
    } else {
      return {};
    }
  })
}

// 查询电子围栏表详细
export function getMapfenceByIds(ids) {
  if (debug) {
    if (!Array.isArray(ids)) {
      ids = [ids]
    }
    let cached = cache.filter(c => ids.includes(c.id+''))
    return Promise.resolve({
      data: {
        list: cached,
        count: cached.length
      }
    });
  }
}

// 新增电子围栏表
export function addMapfence(data) {
  if (debug) {
    data.id=id++;
    cache.push(data)
    return Promise.resolve({
      code: 200,
      msg: 'ok'
    })
  }
  return request({
    url: '/shipping_fence/store',
    method: 'post',
    data: data
  })
}

// 修改电子围栏表
export function updateMapfence(data) {
  if (debug) {
    let cached = cache.filter(c => c.id === data.id)
    if (cached.length) {
      cache[cache.indexOf(cached[0])] = data;
      return Promise.resolve({
        code: 200,
        msg: 'ok'
      });
    } else {
      return Promise.reject({
        code: 404,
        msg: 'data not found'
      })
    }
  }
  return request({
    url: '/shipping_fence/update',
    method: 'post',
    data: data
  })
}

// 删除电子围栏表
export function delMapfence(ids) {
  if (debug) {
    if (!Array.isArray(ids)) {
      ids = [ids]
    }
    let cached = cache.filter(c => ids.includes(c.id))
    if (cached.length) {
      cached.forEach(c => {
        cache.splice(cache.indexOf(c), 1); 
      })
    }
    return Promise.resolve({
      code: 200,
      msg: 'ok'
    });
  }
  return request({
    url: '/shipping_fence/stop',
    method: 'post',
    data: {id: ids}
  })
}

// 导出电子围栏表
export function exportMapfence(query) {
  return request({
    url: '/tms/mapfence/export',
    method: 'get',
    params: query
  })
}